import { combineReducers } from "@reduxjs/toolkit";
import listingReducer from "./listingReducer";
import createReducer from "./createReducer";

const reducer = combineReducers({
  listing: listingReducer,
  create: createReducer,
});

export { reducer };
