import React from "react";
import Navbar from "./Navbar";
import { ProductCategoryTabs } from "./ProductCategoryTabs";
import { ProductGroup } from "./ProductGroup";
import { Footer } from "./Footer";
import "../App.scss";
const MainPage = () => {
  return (
    <div className="App">
      <Navbar />
      <ProductCategoryTabs />
      <ProductGroup />
      <Footer />
    </div>
  );
};

export default MainPage;
