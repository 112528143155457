import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveProductCategory,
  selectLoadProductParams,
  selectProductsByCategory,
} from "../reducers/products/listingSelectors";
import { Products } from "./Products";
import { getProductsByCategory } from "../reducers/products/actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const ProductGroup = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { label } = useSelector(selectActiveProductCategory);
  const { hasMore, pageNum } = useSelector(selectLoadProductParams);
  const locale = i18n.language;

  useEffect(() => {
    dispatch(getProductsByCategory({ category: label, locale }));
  }, [dispatch, label, locale]);

  const elementRef = useRef(null);

  const onIntersection = useCallback(
    (entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting && hasMore) {
        dispatch(
          getProductsByCategory({
            category: label,
            pageNum: pageNum,
            locale,
          }),
        );
      }
    },
    [dispatch, label, locale, pageNum, hasMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current && products.length !== 0) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  const products = useSelector(selectProductsByCategory);
  if (isEmpty(products)) {
    return null;
  }

  return (
    <>
      <Products products={products} />
      <div ref={elementRef} />
    </>
  );
};
