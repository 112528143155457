import React from "react";
import MediaQuery from "react-responsive";
import BaseModalWrapper from "./BaseModalWrapper";
import {
  DesktopCloseButton,
  DesktopHeader,
  DesktopModalContainer,
  MobileCloseButton,
  MobileHeader,
  MobileModalContainer,
} from "./ModalPopup";

const RWDModal = (props) => {
  return (
    <MediaQuery minWidth={580}>
      {(matches) =>
        matches ? (
          <BaseModalWrapper
            HeaderComponent={DesktopHeader}
            CloseButtonComponent={DesktopCloseButton}
            ContainerComponent={DesktopModalContainer}
            {...props}
          />
        ) : (
          <BaseModalWrapper
            HeaderComponent={MobileHeader}
            CloseButtonComponent={MobileCloseButton}
            ContainerComponent={MobileModalContainer}
            {...props}
          />
        )
      }
    </MediaQuery>
  );
};

export default RWDModal;
