import React from "react";

const StarIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 100 100"
    className={className}
  >
    <polygon
      points="50,10 61.8,39.1 92.4,39.1 68.6,61.1 80.5,89.9 50,73.8 19.5,89.9 31.4,61.1 7.6,39.1 38.2,39.1"
      fill="#FFD700"
    />
  </svg>
);

export default StarIcon;
