import { combineReducers } from "@reduxjs/toolkit";
import { reducer as usersReducer } from "./users/reducer";
import { reducer as productsReducer } from "./products/reducer";
import { reducer as chatsReducer } from "./chats/reducer";

export default combineReducers({
  users: usersReducer,
  products: productsReducer,
  chats: chatsReducer,
});
