import { ar, enUS, es, fr, ja, ko, ru, vi, zhCN } from "date-fns/locale";

export const mapLocaleToDateLocale = ({ i18nLocale }) => {
  switch (i18nLocale) {
    case "ar-AR":
      return ar;
    case "en-US":
      return enUS;
    case "en-MX":
      return es;
    case "fr-FR":
      return fr;
    case "ko-KR":
      return ko;
    case "ja-JP":
      return ja;
    case "ru-RU":
      return ru;
    case "vi-VI":
      return vi;
    case "zh-CN":
      return zhCN;
    default:
      return enUS;
  }
};
