import React from "react";
import { languaguesOptions } from "../options/languages";
import { useTranslation } from "react-i18next";
import "./languagesDropdown.scss";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetProductsByCateogory } from "../reducers/products/actions";

const LanguagesDropDown = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLocaleOption =
    languaguesOptions.find(i18n.language)?.title || "en-US";
  const handleChangeLanguage = (locale) => {
    i18n.changeLanguage(locale);
    dispatch(resetProductsByCateogory());
  };

  return (
    <div className="dropdown-container">
      <DropdownButton
        title={t(`${currentLocaleOption}`)}
        className="language-picker-dropdown"
        variant="light"
      >
        {languaguesOptions.map(({ code, title }) => (
          <Dropdown.Item key={code} onClick={() => handleChangeLanguage(code)}>
            {t(`${title}`)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
};

export default LanguagesDropDown;
