import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="top">
        <div className="top-message">
          <h1>Kupon</h1>
          <p>{t("footer_message")}</p>
        </div>
        <div className="top-connect">
          <h3 className="connect-us">{t("footer_connect_us")}</h3>
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-behance-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div>
          <h4>{t("footer_category_deals")}</h4>
          <a href="/">{t("footer_category_deals_over_50")}</a>
          <a href="/">{t("footer_category_deals_under_10")}</a>
          <a href="/">{t("footer_category_deals_most_popular")}</a>
        </div>
        <div className="footer-help">
          <h4>{t("footer_category_help")}</h4>
          <Link to="/contact">{t("footer_category_help_contact_us")}</Link>
        </div>
        <div>
          <h4>{t("footer_category_others")}</h4>
          <Link to="/about">{t("footer_category_others_about_us")}</Link>
          <Link to="/termsofservice">
            {t("footer_category_others_terms_of_service")}
          </Link>
          <Link to="/privacy">
            {t("footer_category_others_privacy_policy")}
          </Link>
        </div>
        <div className="footer-more">
          <h4>{t("footer_category_more")}</h4>
          <a href="/">{t("footer_category_more_news")}</a>
          <a href="/">{t("footer_category_more_subscribe")}</a>
        </div>
      </div>
    </div>
  );
};
