const AlarmIcon = ({ height = "16px", width = "16px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 47.5 47.5"
    viewBox="0 0 47.5 47.5"
    id="alarm"
    height={height}
    width={width}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 38h38V0H0v38Z"></path>
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)">
      <path
        fill="#ffcc4d"
        d="M0 0a2.012 2.012 0 0 0-2-2.014c-1.097 0-2 .902-2 2.014v4.028c0 1.112.903 2.014 2 2.014s2-.902 2-2.014V0Z"
        transform="translate(21 30.959)"
      ></path>
      <path
        fill="#ffac33"
        d="M0 0c-.224 0-.452.052-.666.159a1.522 1.522 0 0 0-.666 2.028l8.94 18.126c.253.512.768.835 1.333.835s1.08-.323 1.332-.835l8.942-18.126a1.522 1.522 0 0 0-.666-2.028 1.482 1.482 0 0 0-1.999.676L8.941 16.26 1.333.835A1.486 1.486 0 0 0 0 0"
        transform="translate(10.18 1)"
      ></path>
      <path
        fill="#58595b"
        d="M0 0a.985.985 0 0 0-.702.295l-13.907 14.099a1.017 1.017 0 0 0 0 1.424.983.983 0 0 0 1.404 0L0 2.432l13.203 13.386a.985.985 0 0 0 1.405 0 1.017 1.017 0 0 0 0-1.424L.703.295A.988.988 0 0 0 0 0"
        transform="translate(19.121 16.608)"
      ></path>
      <path
        fill="#dd2e44"
        d="M0 0c0-8.898-7.115-16.111-15.894-16.111-8.777 0-15.893 7.213-15.893 16.111 0 8.9 7.116 16.113 15.893 16.113C-7.115 16.113 0 8.9 0 0"
        transform="translate(35.015 17.615)"
      ></path>
      <path
        fill="#e6e7e8"
        d="M0 0c0-6.674-5.335-12.084-11.92-12.084-6.583 0-11.919 5.41-11.919 12.084 0 6.675 5.336 12.085 11.919 12.085C-5.335 12.085 0 6.675 0 0"
        transform="translate(31.041 17.615)"
      ></path>
      <path
        fill="#ffcc4d"
        d="M0 0a5.899 5.899 0 0 1-4.214-1.77l8.429-8.544A6.066 6.066 0 0 1 5.96-6.042C5.96-2.706 3.291 0 0 0"
        transform="translate(31.04 35.743)"
      ></path>
      <path
        fill="#ffcc4d"
        d="M0 0a5.9 5.9 0 0 0 4.214-1.77l-8.429-8.544A6.07 6.07 0 0 0-5.96-6.042C-5.96-2.706-3.292 0 0 0"
        transform="translate(6.96 35.743)"
      ></path>
      <path
        fill="#414042"
        d="M0 0h-5a1 1 0 0 0-1 1v9a1 1 0 0 0 2 0V2h4a1 1 0 1 0 0-2"
        transform="translate(24 17)"
      ></path>
    </g>
  </svg>
);

export default AlarmIcon;
