import { Options } from "./Options";

const ar = {
  title: "languages_ar",
  code: "ar-AR",
  countryCode: "SA",
};

const en = {
  title: "languages_en",
  code: "en-US",
  countryCode: "US",
};

const es = {
  title: "languages_es",
  code: "es-MX",
  countryCode: "MX",
};

const fil = {
  title: "languages_fil",
  code: "fil-PH",
  countryCode: "PH",
};

const fr = {
  title: "languages_fr",
  code: "fr-FR",
  countryCode: "FR",
};

const ko = {
  title: "languages_ko",
  code: "ko-KR",
  countryCode: "KR",
};

const ja = {
  title: "languages_ja",
  code: "ja-JP",
  countryCode: "JP",
};

const ru = {
  title: "languages_ru",
  code: "ru-RU",
  countryCode: "RU",
};

const vi = {
  title: "languages_vi",
  code: "vi-VI",
  countryCode: "VN",
};

const zh = {
  title: "languages_zh",
  code: "zh-CN",
  countryCode: "CN",
};

const languages = {
  "ar-AR": ar,
  "en-US": en,
  "es-MX": es,
  "fil-PH": fil,
  "fr-FR": fr,
  "ko-KR": ko,
  "ja-JP": ja,
  "ru-RU": ru,
  "vi-VI": vi,
  "zh-CN": zh,
};

export const languaguesOptions = new Options(languages);
