import * as api from "../../api";
import * as types from "./actionTypes";

export const loginUser = ({ data, onClose }) => {
  return (dispatch) => {
    api
      .loginUser({ data })
      .then((response) => {
        dispatch({
          type: types.USERS_LOGIN_USER_SUCCESS,
          payload: { user: response.data },
        });
        onClose();
      })
      .catch((err) => {
        const { response: { data: { message, errorCode } = {} } = {}, code } =
          err;
        if (code === "ERR_NETWORK") {
          console.log({ err });
        } else {
          dispatch({
            type: types.USERS_LOGIN_USER_FAILED,
            payload: { message, errorCode },
          });
        }
      });
  };
};

export const setLoginUserField = (name, value) => {
  return { type: types.USERS_LOGIN_USER_SET_FIELD, payload: { name, value } };
};

export const autoLoginUser = ({ id, firstName, token }) => {
  return {
    type: types.USERS_AUTO_LOGIN_USER,
    payload: { id, firstName, token },
  };
};

export const logoutUser = () => {
  return { type: types.USERS_LOGOUT_USER_SUCCESS };
};

export const addUser = ({ data, onClose, showWelcomeModal }) => {
  return (dispatch) => {
    api
      .addUser({ data })
      .then((response) => {
        dispatch({
          type: types.USERS_SIGNUP_USER_SUCCESS,
          payload: response.data,
        });
        onClose();
        showWelcomeModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};

export const userSendSupportInquery = ({ data }) => {
  return (dispatch) => {
    api
      .postSendMessageRequest({ data })
      .then((response) => {
        dispatch({
          type: types.USERS_USER_SEND_SUPPORT_INQUERY_SUCCESS,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};
