import React, { useState } from "react";
import RWDModal from "./RWDModal";
import { Button, ButtonContainer } from "./ModalPopup";
import "./couponCodeModal.scss";
import { useSelector } from "react-redux";
import { selectProductBySku } from "../reducers/products/listingSelectors";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import LinkIcon from "../icons/LinkIcon";

const CouponCodeModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { url, couponCode } = useSelector(selectProductBySku);
  const { price, listPrice } = useSelector(selectProductBySku);
  const [copyCode, setCopyCode] = useState("product_detail_coupon_code_copy");

  const handleCopyCode = () => {
    navigator.clipboard.writeText(couponCode);
    setCopyCode("product_detail_coupon_code_copied");
  };
  const saving = (listPrice - price).toFixed(2);

  return (
    <RWDModal onClose={onClose}>
      <MediaQuery minWidth={800}>
        {(matches) =>
          matches ? (
            <>
              <div className="coupon-code-detail">
                <div className="coupon-code-emoji">&#127881;</div>
                <div className="coupon-code-saving-header">
                  {t("product_detail_coupon_code_saving_header", { saving })}
                </div>
                <div className="coupon-code-saving-content">
                  {t("product_detail_coupon_code_saving_content")}
                </div>
                <div className="coupon-code-columns-box">
                  <div className="coupon-code coupon-code-btn">
                    {couponCode}
                  </div>
                  {/* <div className="coupon-code-verify coupon-code-btn">
            verify
          </div> */}
                  <div
                    className="coupon-code-copy coupon-code-btn"
                    onClick={handleCopyCode}
                  >
                    {t(copyCode)}
                  </div>
                </div>
              </div>
              <ButtonContainer>
                <Button
                  onClick={() => window.open(`${url}`, "_blank")}
                  className="coupon-code-button"
                >
                  <LinkIcon width="16px" height="16px" />
                  <span>{t("product_detail_purchase_title")}</span>
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <div>
              <div className="mobile-coupon-code-detail">
                <div className="mobile-coupon-code-emoji">&#127881;</div>
                <div className="mobile-coupon-code-saving-header">
                  {t("product_detail_coupon_code_saving_header", { saving })}
                </div>
                <div className="mobile-coupon-code-saving-content">
                  {t("product_detail_coupon_code_saving_content")}
                </div>
                <div className="mobile-coupon-code-columns-box">
                  <div className="coupon-code mobile-coupon-code-btn">
                    {couponCode}
                  </div>
                  {/* <div className="coupon-code-verify coupon-code-btn">
            verify
          </div> */}
                  <div
                    className="mobile-coupon-code-copy mobile-coupon-code-btn"
                    onClick={handleCopyCode}
                  >
                    {t(copyCode)}
                  </div>
                </div>
              </div>
              <ButtonContainer className="mobile-coupon-back-button-container">
                <Button
                  onClick={() => window.open(`${url}`, "_blank")}
                  className="mobile-coupon-code-button"
                >
                  <LinkIcon width="16px" height="16px" />
                  <span>{t("product_detail_purchase_title")}</span>
                </Button>
              </ButtonContainer>
            </div>
          )
        }
      </MediaQuery>
    </RWDModal>
  );
};

export default CouponCodeModal;
