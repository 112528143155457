import React from "react";
import { Footer } from "./Footer";
import { ProductDetailPage } from "./ProductDetailPage";
import Navbar from "./Navbar";
import "../App.scss";

export const ProductPage = () => (
  <div className="App">
    <Navbar />
    <ProductDetailPage />
    <Footer />
  </div>
);
