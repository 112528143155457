import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Products } from "./Products";
import {
  selectSearchProductParams,
  selectSearchProducts,
  selectSearchQuery,
} from "../reducers/products/listingSelectors";
import { NoProductsDisplay } from "./NoProductsDisplay";
import { searchProductsByQuery } from "../reducers/products/actions";

export const ProductSearchGroup = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectSearchProducts);
  const query = useSelector(selectSearchQuery);
  const { hasMore, pageNum } = useSelector(selectSearchProductParams);

  const elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMore) {
      dispatch(searchProductsByQuery({ query, pageNum }));
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  if (products.length === 0) {
    return <NoProductsDisplay />;
  }

  return (
    <>
      <Products products={products} />
      <div ref={elementRef} />
    </>
  );
};
