import React from "react";
import Navbar from "./Navbar";
import { ProductSearchGroup } from "./ProductSearchGroup";
import { Footer } from "./Footer";
import "../App.scss";

export const SearchPage = () => (
  <div className="App">
    <Navbar />
    <ProductSearchGroup />
    <Footer />
  </div>
);
