import React from "react";

const CloseEyeIcon = ({ onClick, className = "" }) => (
  <div onClick={onClick} className={className}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
    >
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M1,32c0,0,11,15,31,15s31-15,31-15S52,17,32,17
    S1,32,1,32z"
      />
      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="32"
        cy="32"
        r="7"
      />
      <line
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeMiterlimit="10"
        x1="9"
        y1="55"
        x2="55"
        y2="9"
      />
    </svg>
  </div>
);

export default CloseEyeIcon;
