import React from "react";
import { toClassNames } from "../utils/strings";
import "./productCategoryTabs.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveProductCategory } from "../reducers/products/actions";
import { productCategories } from "../utils/productCategories";
import { selectActiveProductCategory } from "../reducers/products/listingSelectors";
import { useTranslation } from "react-i18next";

export const ProductCategoryTabs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: selectedId } = useSelector(selectActiveProductCategory);

  const handleTabClick = (productCategory) => {
    dispatch(setActiveProductCategory(productCategory));
  };

  return (
    <div className="product-category-tabs">
      {productCategories.map((productCategory) => {
        const { id, code } = productCategory;
        const isActive = id === selectedId;
        return (
          <div key={id}>
            <button
              className={toClassNames([
                "product-category-tab",
                isActive ? "active" : "",
              ])}
              onClick={() => handleTabClick(productCategory)}
            >
              {t(`${code}`)}
            </button>
            {isActive ? <div className="tab-underline" /> : null}
          </div>
        );
      })}
    </div>
  );
};
