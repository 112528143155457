import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RWDModal from "./RWDModal";
import InputWithIcon from "./InputWithIcon";
import LoginIcon from "../icons/LoginIcon";
import PasswordIcon from "../icons/PasswordIcon";
import EyeIcon from "../icons/EyeIcon";
import CloseEyeIcon from "../icons/CloseEyeIcon";
import LogoIcon from "../icons/LogoIcon";
import { Button, ButtonContainer } from "./ModalPopup";
import "./loginModal.scss";
import { loginUser, setLoginUserField } from "../reducers/users/actions";
import {
  selectErrors,
  selectLoginUser,
} from "../reducers/users/listingSelectors";
import MediaQuery from "react-responsive";

const LoginModal = ({ onShowSignupModal, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pwdVisible, setPwdVisible] = useState(false);

  const errors = useSelector(selectErrors);
  const user = useSelector(selectLoginUser);

  const togglePwd = () => {
    setPwdVisible((wasPwdVisible) => !wasPwdVisible);
  };

  const handleSetField = (event) => {
    const { id, value } = event.target;
    dispatch(setLoginUserField(id, value));
  };

  const handleKeyPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    handleSubmit(event);
  };

  const handleSubmit = async (event) => {
    event.stopPropagation();
    dispatch(loginUser({ data: user, onClose }));
  };

  const handleShowSignupModal = (event) => {
    event.stopPropagation();
    onClose();
    onShowSignupModal();
  };

  const { email = "", password = "" } = user || {};
  const submitButtonDisabled =
    email.length === 0 || password.length === 0 || errors.email !== undefined;
  return (
    <RWDModal
      onClose={onClose}
      LogoComponent={LogoIcon}
      header={"Kupon"}
      message={t("login_modal_title")}
    >
      <MediaQuery minWidth={800}>
        {(matches) =>
          matches ? (
            <div className="login-modal-container">
              <InputWithIcon
                id="email"
                type="text"
                placeholder={t("modal_email_field_placeholder")}
                value={email}
                onChange={handleSetField}
                onKeyPress={!submitButtonDisabled ? handleKeyPress : null}
                icon={<LoginIcon />}
              />
              {errors.email ? (
                <span className="login-error-message">{errors.email}</span>
              ) : null}
              <InputWithIcon
                id="password"
                type={!pwdVisible ? "password" : "text"}
                placeholder={t("modal_password_field_placeholder")}
                value={password}
                icon={<PasswordIcon />}
                icon2={
                  pwdVisible ? (
                    <EyeIcon onClick={togglePwd} />
                  ) : (
                    <CloseEyeIcon onClick={togglePwd} />
                  )
                }
                onChange={handleSetField}
                onKeyPress={!submitButtonDisabled ? handleKeyPress : null}
              />
              {errors.password ? (
                <span className="login-error-message">{errors.password}</span>
              ) : null}
              <div>
                <span className="not-have-account">
                  {t("login_modal_not_have_account")}
                </span>
                <span
                  className="create-account"
                  onClick={handleShowSignupModal}
                >
                  {t("login_modal_create_account")}
                </span>
              </div>
              <ButtonContainer>
                <Button onClick={handleSubmit} disabled={submitButtonDisabled}>
                  <span>{t("common_signin")}</span>
                </Button>
              </ButtonContainer>
            </div>
          ) : (
            <div className="mobile-login-modal-container">
              <InputWithIcon
                id="email"
                type="text"
                containerClassName="mobile-login-form-field"
                placeholder={t("modal_email_field_placeholder")}
                value={email}
                onChange={handleSetField}
                onKeyPress={!submitButtonDisabled ? handleKeyPress : null}
                icon={<LoginIcon />}
              />
              {errors.email ? (
                <span className="login-error-message">{errors.email}</span>
              ) : null}
              <InputWithIcon
                id="password"
                type={!pwdVisible ? "password" : "text"}
                placeholder={t("modal_password_field_placeholder")}
                value={password}
                icon={<PasswordIcon />}
                icon2={
                  pwdVisible ? (
                    <EyeIcon onClick={togglePwd} />
                  ) : (
                    <CloseEyeIcon onClick={togglePwd} />
                  )
                }
                onChange={handleSetField}
                onKeyPress={!submitButtonDisabled ? handleKeyPress : null}
              />
              {errors.password ? (
                <span className="login-error-message">{errors.password}</span>
              ) : null}
              <div>
                <span className="mobile-not-have-account">
                  {t("login_modal_not_have_account")}
                </span>
                <span
                  className="mobile-create-account"
                  onClick={handleShowSignupModal}
                >
                  {t("login_modal_create_account")}
                </span>
              </div>
              <ButtonContainer>
                <Button onClick={handleSubmit} disabled={submitButtonDisabled}>
                  <span className="mobile-form-btn">{t("common_signin")}</span>
                </Button>
              </ButtonContainer>
            </div>
          )
        }
      </MediaQuery>
    </RWDModal>
  );
};

export default LoginModal;
