import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import "./navbar.scss";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import UserIcon from "../icons/UserIcon";
import { selectListingUser } from "../reducers/users/listingSelectors";
import { logoutUser } from "../reducers/users/actions";
import { LogoutIcon } from "../icons/LogoutIcon";
import WelcomeModal from "./WelcomeModal";
import SearchBar from "./SearchBar";
import logoImage from "../images/kupon-logo.png";
import LanguagesDropDown from "./LanguagesDropdown";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isWelcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const { token, firstName } = useSelector(selectListingUser);

  const handleShowWelcomeModal = () => {
    setWelcomeModalVisible(true);
    showWelcomeModal();
  };

  const [showWelcomeModal, hideWelcomeModal] = useModal(() => {
    return <WelcomeModal onClose={hideWelcomeModal} />;
  });

  const [showSignupModal, hideSignupModal] = useModal(() => (
    <SignupModal
      showLoginModal={showLoginModal}
      showWelcomeModal={handleShowWelcomeModal}
      onClose={hideSignupModal}
    />
  ));

  const [showLoginModal, hideLoginModal] = useModal(() => (
    <LoginModal onShowSignupModal={showSignupModal} onClose={hideLoginModal} />
  ));

  const handleLogoutUser = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (isWelcomeModalVisible) {
      const timeoutId = setTimeout(() => {
        hideWelcomeModal();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isWelcomeModalVisible, hideWelcomeModal]);

  const handleNavigateHome = (event) => {
    event.stopPropagation();
    navigate("/");
  };

  return (
    <MediaQuery minWidth={800}>
      {(matches) =>
        matches ? (
          <div className="navbar-desktop-container">
            <div>
              <nav className="navbar-items">
                <h1 className="navbar-logo" onClick={handleNavigateHome}>
                  <img
                    src={logoImage}
                    alt="kupon-logo"
                    className="kupon-logo"
                  />
                </h1>
                <SearchBar />
                <LanguagesDropDown />
                {token === undefined ? (
                  <div className="button-container">
                    <UserIcon className="user-icon" />
                    <button
                      className="login-signup-button"
                      onClick={showLoginModal}
                    >
                      {t("menu_bar_login")}
                    </button>
                  </div>
                ) : (
                  <div className="user-info-container">
                    <div className="name-circle">
                      <span>
                        {firstName.length > 0 ? firstName.charAt(0) : ""}
                      </span>
                    </div>
                    <div className="button-container">
                      <LogoutIcon className="logout-icon" />
                      <div onClick={handleLogoutUser}>
                        {t("menu_bar_logout")}
                      </div>
                    </div>
                  </div>
                )}
              </nav>
            </div>
          </div>
        ) : (
          <nav>
            <div className="navbar-mobile-container">
              <div className="navbar-mobile-first-container">
                <div className="navbar-mobile-item mobile-logo">
                  <h1 className="navbar-logo" onClick={handleNavigateHome}>
                    <img
                      src={logoImage}
                      alt="kupon-logo"
                      className="kupon-logo"
                    />
                  </h1>
                </div>
                <div className="navbar-mobile-item mobile-language-dropdown">
                  <LanguagesDropDown />
                </div>
                <div className="navbar-mobile-item mobile-user-profile-container">
                  <div className="mobile-button-container">
                    <UserIcon onClick={showLoginModal} />
                  </div>
                </div>
              </div>
              <div className="navbar-mobile-item mobile-search-bar">
                <SearchBar />
              </div>
            </div>
          </nav>
        )
      }
    </MediaQuery>
  );
};

export default Navbar;
