import { createSelector } from "@reduxjs/toolkit";

const chatMessagesAndTypingSelector = (state) => {
  const { messages, typing } = state.chats.create;
  return { messages, typing };
};

export const selectChatMessagesAndTyping = createSelector(
  chatMessagesAndTypingSelector,
  ({ messages, typing }) => ({ messages, typing }),
);

export const selectFormattedChatMessages = (state) => {
  const { messages = [] } = state.chats.create;
  return messages.map(({ message, sender }) => ({
    role: sender === "ChatGPT" ? "assistant" : "user",
    content: message,
  }));
};
