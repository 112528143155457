import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  .use(Backend)
  .use(LngDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace) =>
      import(`../public/locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    fallbackLng: "en",
    whitelist: ["ar", "en", "es", "fil", "fr", "ja", "ko", "ru", "vi", "zh"],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
