import * as types from "./actionTypes";
import { CHATGPT, INCOMING } from "./constants";

const initialState = () => {
  return Object.assign(
    {},
    {
      messages: [
        {
          message: "Hello, I am your assistant on Kupon. How may I help you?",
          sender: CHATGPT,
          direction: INCOMING,
        },
      ],
      typing: false,
    },
  );
};

const handleAddNewMessage = (state, action) => {
  const { messages } = state;
  const { message } = action.payload;
  return { ...state, messages: [...messages, message] };
};

const handleReceiveMessageFromChatGPT = (state, action) => {
  const { content } = action.payload;
  const { messages } = state;
  return {
    ...state,
    messages: [
      ...messages,
      { message: content, sender: CHATGPT, direction: INCOMING },
    ],
    typing: false,
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.CHATS_ADD_NEW_MESSAGE:
      return handleAddNewMessage(state, action);
    case types.CHATS_START_SEND_MESSAGE_TO_CHATGPT:
      return { ...state, typing: true };
    case types.CHATS_RECEIVE_MESSAGE_FROM_CHATGPT:
      return handleReceiveMessageFromChatGPT(state, action);
    default:
      return state;
  }
};

export default reducer;
