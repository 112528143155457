import React from "react";
import Modal from "./Modal";
import { CloseSign, Message } from "./ModalPopup";

const BaseModalWrapper = ({
  children,
  onClose,
  ContainerComponent,
  CloseButtonComponent,
  LogoComponent,
  HeaderComponent,
  header,
  message,
}) => {
  const handleCloseButtonClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal onClick={onClose}>
      <ContainerComponent>
        <CloseButtonComponent onClick={handleCloseButtonClick}>
          <CloseSign />
        </CloseButtonComponent>
        {LogoComponent ? <LogoComponent width={40} height={40} /> : null}
        <HeaderComponent>{header}</HeaderComponent>
        {message ? <Message>{message}</Message> : null}
        {children}
      </ContainerComponent>
    </Modal>
  );
};

export default BaseModalWrapper;
