export const setCookies = ({ name, value, options = {} }) => {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  const { expiresInDays, path, domain, secure } = options;
  // Set optional cookie options
  if (expiresInDays !== undefined) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expiresInDays);
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }
  if (options.path !== undefined) {
    cookieString += `; path=${path}`;
  }
  if (domain !== undefined) {
    cookieString += `; domain=${domain}`;
  }
  if (secure !== undefined) {
    cookieString += "; secure";
  }
  // Set the cookie
  document.cookie = cookieString;
};

export const getCookie = ({ name }) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

export const deleteCookie = (cookieName) => {
  // Check if the cookie exists
  const cookie = getCookie({ name: cookieName });
  if (cookie) {
    // Set the cookie's expiration date to a date in the past
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
};
