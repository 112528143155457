import { Option } from "./Option";

const findValueAsKey = (items, value) => {
  const entries = Object.entries(items);
  if (Array.isArray(value)) {
    const foundEntry = entries.find(
      ([key, option]) =>
        option.value.length === value.length &&
        option.value.every((v, i) => v === value[i]),
    );
    return foundEntry ? foundEntry[1] : null;
  } else {
    const foundEntry = entries.find(([key, option]) => option.value === value);
    return foundEntry ? foundEntry[1] : null;
  }
};

const filterItems = (items, filter, valueAsKey) => {
  const result = {};
  let filteredItems = [];

  if (!valueAsKey) {
    filteredItems = Object.entries(items).filter(([key, value]) =>
      filter.includes(key),
    );
  } else {
    filter.forEach((filterValue) => {
      const item = findValueAsKey(items, filterValue);
      filteredItems.push(item);
    });
  }

  filteredItems.forEach(([key, value]) => (result[key] = value));
  return result;
};

const createOptions = (items) => {
  const options = {};

  Object.entries(items).forEach(
    ([key, item]) => (options[key] = createOption(key, item)),
  );
  return options;
};

const createOption = (key, item) => {
  return new Option(key, item);
};

export class Options {
  constructor(items, filter) {
    const keys = Object.keys(items);

    this.items = items;
    // keep a flag if the items have specific values to override what is get/set instead of the option key
    this.valueAsKey = keys && keys[0] && items[keys[0]] && items[keys[0]].value;
    this.options = createOptions(
      filter ? filterItems(items, filter, this.valueAsKey) : items,
    );
    this.keys = Object.keys(this.options);
    this.size = this.keys.length;
  }

  map = (mappingFunction) => {
    return Object.entries(this.options).map(([key, option], index) => {
      return this.valueAsKey
        ? mappingFunction(option, option.value, index)
        : mappingFunction(option, key, index);
    });
  };

  forEach = (applyFunction) => {
    Object.entries(this.options).forEach(([key, option], index) => {
      if (this.valueAsKey) {
        applyFunction(option, option.value, index);
      } else {
        applyFunction(option, key, index);
      }
    });
  };

  // TODO: Refactor this to use options like the other utility functions
  filter = (comparatorFunction) => {
    const filteredItems = {};

    Object.entries(this.items).forEach(([key, option]) => {
      if (comparatorFunction(option, key)) {
        filteredItems[key] = option;
      }
    });

    return new Options(filteredItems);
  };

  filterByKeyArray = (keyArray) => {
    return this.filter((option, key) => keyArray.includes(key));
  };

  clone = () => {
    return new Options({ ...this.items });
  };

  first = () => {
    return this.isEmpty() ? null : this.options[this.keys[0]];
  };

  find = (key) => {
    if (key === null || key === undefined || key === "") {
      return null;
    }

    const result = this.valueAsKey
      ? findValueAsKey(this.options, key)
      : this.options[key];
    return result ? result : null; // make sure to return null instead of undefined
  };

  isEmpty = () => {
    return this.size === 0;
  };

  sort = (comparator) => {
    const keys = Object.keys(this.options);
    keys.sort(comparator);
    const obj = {};
    keys.forEach((k) => {
      obj[k] = { ...this.items[k] };
    });
    return new Options({ ...obj });
  };

  toPuclOptions = () =>
    this.map((option, key) => ({
      ...option,
      label: option.titleCode || option.title,
      value: key,
      id: key,
    }));
}
