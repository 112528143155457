import styled from "styled-components";

const ModalContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const DesktopModalContainer = styled(ModalContainer)`
  border-radius: 7px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
  padding: 40px;
  min-width: 650px;
  font-size: 26px;
  min-height: 400px;
`;

export const MobileModalContainer = styled(ModalContainer)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 20px;
  overflow-y: scroll;
  height: 100vh;
  font-size: 13px;
`;

export const DesktopHeader = styled.h3`
  font-size: 35px;
  line-height: 1em;
  font-weight: 300;
  margin: 10px 0 20px;
  text-align: center;
`;

export const MobileHeader = styled.h3`
  font-size: 25px;
  line-height: 1em;
  font-weight: 300;
  margin: 10px 0 20px;
  text-align: center;
`;

export const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
`;

const CLOSE_BUTTON_SIZE = 40;

const CloseButton = styled.div`
  position: absolute;
  width: ${CLOSE_BUTTON_SIZE}px;
  height: ${CLOSE_BUTTON_SIZE}px;
  background-color: #c8c8c8;
  border-radius: 50%;
  cursor: pointer;

  & > * {
    opacity: 1;
  }

  &:hover > * {
    opacity: 0.4;
  }
`;

export const DesktopCloseButton = styled(CloseButton)`
  top: -${CLOSE_BUTTON_SIZE / 2}px;
  left: calc(100% - ${CLOSE_BUTTON_SIZE / 2}px);
`;

export const MobileCloseButton = styled(CloseButton)`
  top: -${CLOSE_BUTTON_SIZE / 20 - 2}px;
  left: calc(100% - ${CLOSE_BUTTON_SIZE * 1 + 10}px);
`;

export const CloseSign = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #323232;

  &:before,
  &:after {
    position: absolute;
    left: 19px;
    top: 10px;
    content: " ";
    height: 20px;
    width: 2px;
    background-color: #323232;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Error = styled.div`
  padding: 16px 0;
  font-size: 13px;
  color: red;
`;

export const ButtonContainer = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled.button`
  cursor: pointer;
  border: 3px solid rgb(235, 235, 235);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 13px 16px;
  line-height: 1em;
  background-color: rgb(235, 235, 235);
  position: relative;

  &:disabled:hover {
    color: black;
    background-color: rgb(143, 143, 143);
    border-color: white;
  }

  &:not([disabled]) {
    border-color: white;
    background-color: #befc95;
    color: black;
  }

  &:not([disabled]):hover {
    background-color: #d0ffb1;
  }

  span {
    display: block;
    position: relative;
  }
`;
