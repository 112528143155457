/**
 * Cookies Constants
 */
export const USER_TOKEN = "userToken";
export const USER_FIRST_NAME = "userFirstName";
export const USER_ID = "userId";
export const EXPIRES = "expires";

/**
 * Products Constants
 */
export const AMAZON = "amazon";
