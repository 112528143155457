export class Option {
  constructor(key, item) {
    this.key = key;
    if (item) {
      Object.entries(item).forEach(([key, value]) => (this[key] = value));
    }
  }

  matches = (key) => {
    return this.key === key;
  };
}
