import { createSelector } from "@reduxjs/toolkit";

export const selectErrors = (state) => state?.users?.listing?.errors;
export const selectLoginUser = (state) => state?.users?.listing?.user;

export const listingUserSelector = (state) => state?.users?.listing;

export const selectListingUser = createSelector(
  listingUserSelector,
  (userCredential) => userCredential,
);
