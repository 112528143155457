import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  input {
    display: block;
    background: none;
    border: none;
    width: 450px;
    font-size: 20px;
    &:focus {
      outline: none;
    }
  }
`;

const InputContainer = styled(InputWrapper)`
  margin-bottom: 10px;
  width: 100%;
  font-size: 25px;
  border: 1px solid #e5e7eb;
  padding: 5px;
  border-radius: 7px;

  &:hover {
    border: 2px solid;
  }
`;

const IconContainer = styled.div`
  width: 33px;
  padding-left: 6px;
`;

const IconContainerWithToggle = styled.div`
  width: 33px;
  padding-left: 6px;

  &:hover {
    cursor: pointer;
    background-color: #e5e7eb;
    border-radius: 20px;
  }

  svg&:hover {
    cursor: pointer;
    background-color: #e5e7eb;
    border-radius: 20px;
    top: 2px;
  }
`;

const ModalInput = styled.input`
  display: inline-block;
  outline: none;
  padding: 5px 0;
  margin: 5px 0;
  width: 100%;
  text-indent: 8px;
`;

const InputWithIcon = ({
  icon,
  icon2,
  containerClassName,
  IconContainerClassName,
  ...props
}) => {
  return (
    <InputContainer className={containerClassName}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <ModalInput {...props} />
      {icon2 && (
        <IconContainerWithToggle className={IconContainerClassName}>
          {icon2}
        </IconContainerWithToggle>
      )}
    </InputContainer>
  );
};

export default InputWithIcon;
