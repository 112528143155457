import React from "react";

const CouponIcon = ({ width = "20px", height = "20px" }) => (
  <svg
    className="svg-icon"
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M883.2 668c0 43.1 33.7 77.9 75.5 77.9v120.6c0 19.5-15.9 35.3-35.3 35.3H100.6c-19.5 0-35.3-15.7-35.3-35.3V745.9c41.6 0 75.3-34.8 75.3-77.9 0-43-33.7-77.8-75.3-77.8V469.6c0-19.5 15.7-35.3 35.3-35.3h822.8c19.4 0 35.3 15.7 35.3 35.3v120.6c-41.8 0-75.5 34.8-75.5 77.8z"
      fill="#33ABE3"
    />
    <path
      d="M923.4 922H100.6c-30.6 0-55.5-24.9-55.5-55.5V725.7h20.2c30.4 0 55.1-25.9 55.1-57.7 0-31.8-24.7-57.6-55.1-57.6H45.1V469.6c0-30.6 24.9-55.5 55.5-55.5h822.8c30.6 0 55.5 24.9 55.5 55.5v140.8h-20.2c-30.5 0-55.3 25.8-55.3 57.6s24.8 57.7 55.3 57.7h20.2v140.8c0 30.6-24.9 55.5-55.5 55.5zM85.6 763.9v102.6c0 8.3 6.8 15 15.1 15h822.8c8.3 0 15.1-6.8 15.1-15V763.9C895.4 754.4 863 715 863 668c0-46.9 32.4-86.3 75.5-95.8V469.6c0-8.3-6.8-15.1-15.1-15.1H100.6c-8.3 0-15.1 6.8-15.1 15.1v102.6c43 9.5 75.3 48.9 75.3 95.8 0.1 47-32.2 86.4-75.2 95.9z"
      fill="#403F3F"
    />
    <path
      d="M986.1 366.1h-4.8v-11.9c0-20.5-16.7-37-37-37H79.8c-20.5 0-37 16.5-37 37v417c0 20.5 16.5 37 37 37h864.3c20.4 0 37-16.5 37-37V668.3h4.8V366.1z"
      fill="#33ABE3"
    />
    <path
      d="M944.2 828.5H79.8c-31.6 0-57.3-25.7-57.3-57.3v-417c0-31.6 25.7-57.3 57.3-57.3h864.3c28.7 0 52.6 21.3 56.6 48.9h5.5v342.7h-4.9v82.7c0.1 31.6-25.6 57.3-57.1 57.3zM79.8 337.4c-9.3 0-16.8 7.6-16.8 16.8v417c0 9.3 7.6 16.8 16.8 16.8h864.3c9.3 0 16.8-7.6 16.8-16.8V648.1h4.9V386.3H961v-32.1c0-9.3-7.6-16.8-16.8-16.8H79.8z"
      fill="#403F3F"
    />
    <path
      d="M1000.8 714.7H23.2c-1.7 0-3-1.3-3-3V203c0-1.7 1.3-3 3-3h977.6c1.7 0 3 1.3 3 3v508.7c0 1.7-1.4 3-3 3z"
      fill="#33ABE3"
    />
    <path
      d="M963.4 734.9H60.6C27.2 734.9 0 707.7 0 674.3V240.4c0-33.4 27.2-60.6 60.6-60.6h902.7c33.4 0 60.6 27.2 60.6 60.6v433.9c0.1 33.4-27.1 60.6-60.5 60.6zM60.6 220.2c-11.1 0-20.2 9.1-20.2 20.2v433.9c0 11.1 9.1 20.2 20.2 20.2h902.7c11.1 0 20.2-9.1 20.2-20.2V240.4c0-11.1-9.1-20.2-20.2-20.2H60.6z"
      fill="#403F3F"
    />
    <path
      d="M882.9 418.5c0 76.7 54.2 139.1 120.8 139.1V670c0 24.8-17.5 44.7-38.8 44.7H59.1c-21.5 0-38.8-19.9-38.8-44.7V557.6c65.5-1.9 118-63.6 118-139.1 0-75.7-52.5-137.2-118-139.1V166.9c0-24.8 17.3-44.7 38.8-44.7h905.8c21.4 0 38.8 19.9 38.8 44.7v112.4c-66.7 0.1-120.8 62.3-120.8 139.2z"
      fill="#FFFFFF"
    />
    <path
      d="M964.9 734.9H59.1c-32.6 0-59-29.1-59-64.9V538l19.6-0.6c54.2-1.6 98.4-54.9 98.4-118.9S73.9 301.1 19.6 299.6L0 299V166.9C0 131.1 26.5 102 59 102h905.8c32.6 0 59 29.1 59 64.9v132.6h-20.2c-55.5 0-100.6 53.3-100.6 118.9s45.1 118.9 100.6 118.9h20.2V670c0.1 35.8-26.4 64.9-58.9 64.9zM40.4 575.7V670c0 13.5 8.4 24.5 18.6 24.5h905.8c10.1 0 18.6-11.2 18.6-24.5v-93.9c-68.3-11.1-120.9-77.6-120.9-157.7s52.6-146.6 120.9-157.7v-93.9c0-13.3-8.5-24.5-18.6-24.5H59.1c-10.3 0-18.6 11-18.6 24.5v94.3c67.6 12.4 118 77.7 118 157.3S108 563.3 40.4 575.7z"
      fill="#403F3F"
    />
    <path
      d="M423.7 330.2m-65 0a65 65 0 1 0 130 0 65 65 0 1 0-130 0Z"
      fill="#FFFFFF"
    />
    <path
      d="M423.7 415.5c-47 0-85.3-38.3-85.3-85.3s38.2-85.3 85.3-85.3 85.3 38.2 85.3 85.3-38.2 85.3-85.3 85.3z m0-130.1c-24.7 0-44.8 20.1-44.8 44.8S399 375 423.7 375s44.8-20.1 44.8-44.8-20-44.8-44.8-44.8z"
      fill="#403F3F"
    />
    <path
      d="M600.2 506.7m-65 0a65 65 0 1 0 130 0 65 65 0 1 0-130 0Z"
      fill="#FFFFFF"
    />
    <path
      d="M600.2 592c-47 0-85.3-38.2-85.3-85.3s38.3-85.3 85.3-85.3 85.3 38.2 85.3 85.3-38.3 85.3-85.3 85.3z m0-130.1c-24.7 0-44.8 20.1-44.8 44.8 0 24.7 20.1 44.8 44.8 44.8s44.8-20.1 44.8-44.8c0-24.7-20.1-44.8-44.8-44.8zM358.7 592c-5.2 0-10.3-2-14.3-5.9-7.9-7.9-7.9-20.7 0-28.6L651 250.9c7.9-7.9 20.7-7.9 28.6 0 7.9 7.9 7.9 20.7 0 28.6L373 586c-4 4-9.1 6-14.3 6z"
      fill="#403F3F"
    />
  </svg>
);

export default CouponIcon;
