import { createSelector } from "@reduxjs/toolkit";

const loginCredentialSelector = (state) => {
  const { id, firstName, token } = state?.users?.create;
  return { id, firstName, token };
};

export const selectLoginCredential = createSelector(
  loginCredentialSelector,
  ({ id, firstName, token }) => ({ id, firstName, token }),
);
