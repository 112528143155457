import React from "react";
import { useSearchParams } from "react-router-dom";
import "./noProductsDisplay.scss";

export const NoProductsDisplay = () => {
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get("q");

  const handleRedirectToAmazon = () => {
    window.open(`https://www.amazon.com/s?k=${queryParam}`, "_blank");
  };

  return (
    <div className="no-product-container">
      <div>
        <img src="./Oops.png" alt="Oops" className="no-product-image" />
      </div>
      <p className="no-product-message">No deal has been found.</p>
      <button
        className="find-missing-item-on-amazon"
        onClick={handleRedirectToAmazon}
      >
        Find it on Amazon
      </button>
    </div>
  );
};
